<template>
  <el-collapse value="Filter" accordion>
    <el-collapse-item title="Filter" name="Filter">
      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthFilter"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doFilter"
        @keyup.enter.native="doFilter"
        class="filter"
        ref="form"
      >
        <el-row>
          <el-col :lg="4" :md="8" :sm="12">
            <el-form-item
              :label="fields.mitarbeiterNr.label"
              :prop="fields.mitarbeiterNr.name"
            >
              <el-input v-model="model[fields.mitarbeiterNr.name]" />
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.kostenstelle.label"
              :prop="fields.kostenstelle.name"
            >
              <app-autocomplete-many-input
                :fetchFn="fields.kostenstelle.fetchFn"
                v-model="model[fields.kostenstelle.name]"
              ></app-autocomplete-many-input>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :md="10" :sm="14">
            <el-form-item
              :label="fields.mandantFilter.label"
              :prop="fields.mandantFilter.name"
            >
              <app-autocomplete-many-input
                :fetchFn="fields.mandantFilter.fetchFn"
                v-model="model[fields.mandantFilter.name]"
              ></app-autocomplete-many-input>
            </el-form-item>
          </el-col>
          <el-col style="margin-bottom: -0.41px" :lg="6" :md="12" :sm="16">
            <el-form-item
              :label="fields.createdAtRange.label"
              :prop="fields.createdAtRange.name"
            >
              <el-date-picker
                :format="elementUiDateFormat"
                type="daterange"
                v-model="model[fields.createdAtRange.name]"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="4" :md="8" :sm="12">
            <el-form-item
              :label="fields.vollname.label"
              :prop="fields.vollname.name"
            >
              <el-input v-model="model[fields.vollname.name]" />
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :sm="24">
            <el-form-item
              :label="fields.verwendungKostenstelle.label"
              :prop="fields.verwendungKostenstelle.name"
            >
              <app-autocomplete-many-input
                :fetchFn="fields.verwendungKostenstelle.fetchFn"
                v-model="model[fields.verwendungKostenstelle.name]"
              ></app-autocomplete-many-input>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :md="10" :sm="14">
            <el-form-item
              :label="fields.fakultaet.label"
              :prop="fields.fakultaet.name"
            >
              <app-autocomplete-many-input
                :fetchFn="fields.fakultaet.fetchFn"
                v-model="model[fields.fakultaet.name]"
              ></app-autocomplete-many-input>
            </el-form-item>
          </el-col>
          <el-col style="margin-bottom: -0.41px" :lg="6" :md="12" :sm="16">
            <el-form-item
              :label="fields.updatedAtRange.label"
              :prop="fields.updatedAtRange.name"
            >
              <el-date-picker
                :format="elementUiDateFormat"
                type="daterange"
                v-model="model[fields.updatedAtRange.name]"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="2" :md="4" :sm="6">
            <el-form-item
              :label="fields.ohneKostenstelle.label"
              :prop="fields.ohneKostenstelle.name"
            >
              <el-checkbox
                v-model="model[fields.ohneKostenstelle.name]"
              ></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :lg="2" :md="4" :sm="6">
            <el-form-item
              :label="fields.onlyInactive.label"
              :prop="fields.onlyInactive.name"
            >
              <el-checkbox
                v-model="model[fields.onlyInactive.name]"
              ></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :lg="2" :md="4" :sm="6">
            <el-form-item
              :label="fields.onlyInKarenz.label"
              :prop="fields.onlyInKarenz.name"
            >
              <el-checkbox
                v-model="model[fields.onlyInKarenz.name]"
              ></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :lg="2" :md="4" :sm="6">
            <el-form-item
              :label="fields.onlyInPlan.label"
              :prop="fields.onlyInPlan.name"
            >
              <el-checkbox
                v-model="model[fields.onlyInPlan.name]"
              ></el-checkbox>
            </el-form-item>
          </el-col>

          <el-col :span="12" :offset="0"></el-col>
        </el-row>

        <div class="filter-buttons">
          <el-button
            size="small"
            :disabled="loading"
            @click="doFilter"
            icon="el-icon-fa-search"
            type="primary"
          >
            <app-i18n code="common.search"></app-i18n>
          </el-button>

          <el-button
            size="small"
            :disabled="loading"
            @click="doResetFilter"
            icon="el-icon-fa-undo"
          >
            <app-i18n code="common.reset"></app-i18n>
          </el-button>
        </div>
      </el-form>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { MitarbeiterModel } from '@/modules/mitarbeiter/mitarbeiter-model';

const { fields } = MitarbeiterModel;

const filterSchema = new FilterSchema([
  fields.id,
  fields.mitarbeiterNr,
  fields.mandantFilter,
  fields.kostenstelle,
  fields.verwendungKostenstelle,
  fields.vollname,
  fields.fakultaet,
  fields.updatedAtRange,
  fields.createdAtRange,
  fields.ohneKostenstelle,
  fields.onlyInactive,
  fields.onlyInKarenz,
  fields.onlyInPlan,
]);

export default {
  name: 'app-mitarbeiter-list-filter',

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      loading: 'mitarbeiter/list/loading',
      filter: 'mitarbeiter/list/filter',
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    this.model = filterSchema.initialValues(this.filter, this.$route.query);

    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'mitarbeiter/list/doReset',
      doFetch: 'mitarbeiter/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.model.onlyInactive = false;
      this.model.onlyInKarenz = false;
      this.model.onlyInPlan = false;
      this.model.ohneKostenstelle = false;
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style></style>
