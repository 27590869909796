<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55"></el-table-column>

      <el-table-column
        :label="fields.mitarbeiterNr.label"
        :prop="fields.mitarbeiterNr.name"
        width="70"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'mitarbeiterNr') }}
          </strong>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.vollname.label"
        :prop="fields.vollname.name"
        width="250"
      >
        <template v-slot="scope">
          <strong>{{ presenter(scope.row, 'vollname') }}</strong>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.mandant.label"
        :prop="fields.mandant.name"
        width="100"
        align="center"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.mandant.label"
            :readPermission="fields.mandant.readPermission"
            :navigatePermission="false"
            :value="presenter(scope.row.anstellung, 'mandant')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.kostenstelle.label"
        :prop="fields.kostenstelle.name"
        width="300"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-many
            :label="fields.kostenstelle.label"
            :readPermission="fields.kostenstelle.readPermission"
            :navigatePermission="false"
            :value="
              presenterGrundgehalt(
                scope.row.anstellung.grundgehalt,
                'kostenstelle',
              )
            "
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.verwendungKostenstelle.label"
        :prop="fields.verwendungKostenstelle.name"
        width="350"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-many
            :label="fields.verwendungKostenstelle.label"
            :readPermission="fields.verwendungKostenstelle.readPermission"
            :navigatePermission="false"
            :value="
              presenterGrundgehalt(
                scope.row.anstellung.grundgehalt,
                'verwendungKostenstelle',
              )
            "
          ></app-list-item-relation-to-many>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.grundgehaltGesamtbrutto.label"
        :prop="fields.grundgehaltGesamtbrutto.name"
        sortable="custom"
        width="100"
        align="right"
      >
        <template v-slot="scope">
          <div style="text-align: right !important">
            <strong>
              {{ presenter(scope.row, 'grundgehaltGesamtbrutto') }}
            </strong>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.gesamtwochenstunden.label"
        :prop="fields.gesamtwochenstunden.name"
        sortable="custom"
        width="90"
        align="right"
      >
        <template v-slot="scope">
          <strong>
            {{ presenter(scope.row, 'gesamtwochenstunden') }}
          </strong>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.status.label"
        :prop="fields.status.name"
        width="125"
        align="center"
      >
        <template v-slot="scope">
          <el-tag
            :type="getStatusTagType(scope.row[fields.status.name])"
            v-if="isStatusNull(scope.row[fields.status.name])"
          >
            {{ presenter(scope.row, 'status') }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="180"
      >
        <template v-slot="scope">
          <div class="table-actions">
            <router-link
              :to="`/mitarbeiter/${scope.row.id}/edit`"
              v-if="hasPermissionToEdit"
            >
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>
            <router-link :to="`/mitarbeiter/${scope.row.id}`">
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>
            <!-- <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button> -->
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { MitarbeiterModel } from '@/modules/mitarbeiter/mitarbeiter-model';
import { mapGetters, mapActions } from 'vuex';
import { MitarbeiterPermissions } from '@/modules/mitarbeiter/mitarbeiter-permissions';
import { i18n } from '@/i18n';

const { fields } = MitarbeiterModel;

export default {
  name: 'app-mitarbeiter-list-table',

  mounted() {
    this.$watch(
      () => {
        return this.$refs.table.selection;
      },
      (rows) => {
        this.$store.dispatch('mitarbeiter/list/doSetSelectedRows', rows);
      },
    );
  },

  computed: {
    ...mapGetters({
      rows: 'mitarbeiter/list/rows',
      count: 'mitarbeiter/list/count',
      loading: 'mitarbeiter/list/loading',
      pagination: 'mitarbeiter/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'mitarbeiter/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new MitarbeiterPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new MitarbeiterPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'mitarbeiter/list/doChangeSort',
      doChangePaginationCurrentPage:
        'mitarbeiter/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'mitarbeiter/list/doChangePaginationPageSize',
      doMountTable: 'mitarbeiter/list/doMountTable',
      doDestroy: 'mitarbeiter/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      return MitarbeiterModel.presenter(row, fieldName);
    },

    presenterGrundgehalt(rows, fieldName) {
      const tmp = {};

      const filter = rows.filter((f) => f[`${fieldName}`] !== null);
      tmp[`${fieldName}`] = filter.map((f) => f[`${fieldName}`]);

      return MitarbeiterModel.presenter(tmp, fieldName);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
    isStatusNull(status) {
      return status === null ? false : true;
    },

    getStatusTagType(status) {
      if (status === 'active') {
        return 'success';
      }
      if (status === 'requested' || status === 'revisionRequested') {
        return 'warning';
      }
      if (status === 'rejected') {
        return 'danger';
      }
      if (status === 'releasePending') {
        return '';
      }
      if (status === 'inactive') {
        return 'info';
      }
      return '';
    },
  },
};
</script>

<style></style>
